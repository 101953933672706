<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-layout wrap justify-start pa-5>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg8 md8 sm12>
                    <span class="heading">Hospitals Map</span>
                  </v-flex>
                  <v-flex xs12 lg4 md4 sm12 class="text-end">
                    <span class="subheading"
                      >Total Records Found: {{ totalcount }}</span
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <v-card flat>
                      <v-layout wrap justify-center>
                        <v-flex xs12 sm12 lg12>
                          <v-layout wrap justify-start>
                            <v-flex xs12>
                              <v-layout wrap justify-start>
                                <v-flex xs12 sm12 md11 lg8>
                                  <v-layout wrap>
                                    <v-flex
                                      xs3
                                      sm1
                                      md1
                                      lg1
                                      class="text-left subheading"
                                    >
                                      <v-checkbox
                                        v-model="alldata"
                                        label="All"
                                        @change="checkUpdate()"
                                        class="small-checkbox no-space"
                                      ></v-checkbox>
                                    </v-flex>
                                    <v-flex
                                      xs6
                                      sm2
                                      md2
                                      lg2
                                      class="text-left subheading"
                                    >
                                      <v-checkbox
                                        v-model="hospitalnamedata"
                                        label="Hospital Name"
                                        :disabled="alldata"
                                        class="small-checkbox no-space"
                                      ></v-checkbox>
                                    </v-flex>
                                    <v-flex
                                      xs3
                                      sm2
                                      md2
                                      lg2
                                      class="text-left subheading"
                                    >
                                      <v-checkbox
                                        v-model="districtdata"
                                        label="District"
                                        :disabled="alldata"
                                        class="small-checkbox no-space"
                                      ></v-checkbox>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap justify-start>
                                <v-flex xs12 lg4 pb-2 v-if="hospitalnamedata">
                                  <v-layout wrap justify-start>
                                    <v-flex xs12 class="subheading">
                                      <!-- <span>Search Hospital</span> -->
                                      <v-text-field
                                        label="Enter Name"
                                        dense
                                        hide-details="true"
                                        v-model="hospitalname"
                                        outlined
                                      ></v-text-field>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                                <v-flex xs12 lg4 pb-2 v-if="districtdata">
                                  <v-layout wrap justify-start>
                                    <v-flex
                                      xs12
                                      class="subheading"
                                      :pl-lg-2="hospitalnamedata ? true : false"
                                      :pl-md-2="hospitalnamedata ? true : false"
                                      :pl-sm-2="hospitalnamedata ? true : false"
                                    >
                                      <!-- <span>Search Hospital</span> -->
                                      <v-select
                                        outlined
                                        hide-details
                                        dense
                                        placeholder="Select District"
                                        :items="districts"
                                        v-model="keyword"
                                      >
                                      </v-select>
                                    </v-flex>
                                  </v-layout>
                                </v-flex>
                              </v-layout>
                            </v-flex>

                            <v-flex
                              xs12
                              v-if="hospitalnamedata || districtdata"
                            >
                              <v-layout wrap justify-start>
                                <v-flex
                                  xs12
                                  lg2
                                  pb-2
                                  align-self-center
                                  class="subheading"
                                >
                                  <v-btn color="success" @click="searchhosp()"
                                    ><span>Search</span></v-btn
                                  >
                                </v-flex>
                                <v-flex
                                  xs12
                                  lg2
                                  align-self-center
                                  pl-lg-3
                                  pb-2
                                  class="subheading"
                                >
                                  <v-btn color="warning" @click="reset()"
                                    ><span>Reset</span></v-btn
                                  >
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12>
                              <v-layout wrap justify-center>
                                <v-flex xs12>
                                  <div id="map"></div>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
    <script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBpukNl1L1qGqEwad87A1-MkZj8H_bKDZM&libraries=visualization,drawing"></script>
    <script>
var marker;
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      data: [],
      reportedimages: [],
      map: "",
      mapData: {},
      location: [],
      keyword: "",
      districts: [
        "Palakkad",
        "Alappuzha",
        "Malappuram",
        "Kannur",
        "Wayanad",
        "Kozhikode",
        "Thrissur",
        "Thiruvananthapuram",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Kollam",
        "Pathanamthitta",
        "Kasaragod",
      ],
      approveDialog: false,
      closeremarks: "",
      rejectDialog: false,
      hospitalname: "",
      totalcount: 0,
      hospitalnamedata: false,
      alldata: true,
      districtdata: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    initMap() {
      var vm = this;
      var mapCanvas = document.getElementById("map");

      // Set default map options
      var mapOptions = {
        zoom: 9,
        center: new google.maps.LatLng(9.082, 76.271),
        mapTypeId: google.maps.MapTypeId.TERRAIN,
      };

      // Create a new map
      vm.map = new google.maps.Map(mapCanvas, mapOptions);

      // Check if location data is available
      if (vm.data && vm.data.length > 0) {
        vm.data.forEach((user) => {
          if (user.location && user.location.length === 2) {
            const lat = user.location[1]; // Latitude
            const lon = user.location[0]; // Longitude

            // Create a marker at the location
            const marker = new google.maps.Marker({
              map: vm.map,
              position: new google.maps.LatLng(lat, lon),
              icon: {
                url: require("@/assets/images/highthreat.png"), // Change the URL for a custom marker
                scaledSize: new google.maps.Size(20, 20), // Adjust the size of the icon
              },
            });

            // Create an InfoWindow with user details
            const infoContent = `
    <div style="font-size: 14px; line-height: 1.5;width: 200px; height: auto;">
      <p><span style="font-size: 13px;font-family: sofiaProMedium;"><strong><b>${user.name}</b></strong></span></p>
    </div>
  `;

            const infowindow = new google.maps.InfoWindow({
              content: infoContent,
            });

            // Add a click event listener to the marker to open the InfoWindow
            marker.addListener("click", function () {
              infowindow.open(vm.map, marker);
            });
          }
        });
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hospital/all",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.data = response.data.data;
              this.totalcount = this.data.length;
              this.appLoading = false;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.initMap();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    searchhosp() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hospital/search",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          name: this.hospitalname,
          district: this.keyword,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.data = response.data.data;
              this.appLoading = false;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.initMap();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    closeoperation() {
      if (!this.closeremarks) {
        this.msg = "Please enter remarks.";
        this.showSnackBar = true;
        return;
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/operation/closereport",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          remarks: this.closeremarks,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Report Closed Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(date) {
      if (!date) return "-";
      const [year, month, day] = date.slice(0, 10).split("-");
      return `${day}-${month}-${year}`;
    },
    reset() {
      this.hospitalname = "";
      this.getData();
    },
    checkUpdate() {
      if (this.alldata == true) {
        this.hospitalnamedata = false;
        this.districtdata = false;
        this.keyword = "";
        this.hospitalname = "";
        this.getData();
      }
    },
  },
};
</script>
          <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
#map {
  height: 700px !important;
  width: 100%;
}
</style>