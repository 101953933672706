<template>
  <div style="background-color: #f6f8fa">
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center pa-5 style="background-color: #ffffff">
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 md6 sm6 lg6 class="text-left" align-self-center>
                <span class="heading" style="color: rgba(0, 0, 0, 0.8)"
                  >Dashboard</span
                >
              </v-flex>
              <v-flex xs12 md6 sm6 lg6 class="text-right">
                <v-layout wrap justify-end>
                  <v-flex xs12 lg4 sm4 md4>
                    <v-select
                      :items="dateitems"
                      dense
                      hide-details="true"
                      solo
                      flat
                      background-color="#D1E0FCB2"
                      prepend-inner-icon="mdi-calendar-text"
                      v-model="datefilter"
                      class="custom-select-background dropdown"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex
                xs12
                md3
                sm3
                lg3
                v-for="(card, index) in cardData"
                :key="index"
                pa-1
              >
                <v-card elevation="3" @click="redirectToPage(card.title)">
                  <v-layout wrap justify-start pa-5>
                    <v-flex xs12>
                      <v-layout>
                        <v-flex xs3>
                          <v-layout wrap justify-start>
                            <v-flex xs12 text-left>
                              <v-img :src="card.image"></v-img>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs8 pl-2>
                          <v-layout wrap justify-start>
                            <v-flex xs12 lg10>
                              <span class="cardheader">{{ card.title }}</span>
                            </v-flex>
                            <v-flex xs12>
                              <span class="cardvalue">{{ card.value }}</span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs1 lg1>
                          <v-img
                            height="80px"
                            width="5px"
                            :src="card.vert"
                          ></v-img>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex
                xs12
                md3
                sm3
                lg3
                v-for="(card, index) in casecardData"
                :key="index"
                pa-1
              >
                <v-card elevation="1" style="border-radius: 10px;">
                  <v-layout wrap justify-center>
                    <v-flex xs5 lg5 sm6 md5 px-5 py-3>
                      <span
                        style="
                          font-family: poppinsregular;
                          font-weight: 700;
                          font-size: 30px;
                          color: #000000;
                        "
                        >{{ card.value }}</span
                      >
                    </v-flex>
                    <v-flex xs7 lg7 sm6 md7 class="text-center baccolor">
                      <v-layout wrap justify-center>
                        <v-flex xs12 align-self-center pt-5>
                          <span
                            style="
                              font-family: poppinsregular;
                              font-weight: 500;
                              font-size: 15px;
                              color: #ffffff;
                            "
                            >{{ card.title }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center pa-5>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <!-- <v-flex xs12 sm12 md12 lg6>
                <v-card elevation="1" height="100%">
                  <v-layout wrap justify-start px-5 pt-5>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12
                          ><span class="reportone"
                            >Conflict Reports by Animal Type</span
                          ></v-flex
                        >
                        <v-flex xs12 pt-2
                          ><span class="reporttwo"
                            >Detailed Breakdown of Incidents Across
                          </span></v-flex
                        >
                        <v-flex xs12 pt-3
                          ><span class="reportthree"
                            >Total Conflicts Reported</span
                          ></v-flex
                        >
                        <v-flex xs12
                          ><span class="reportfour">{{
                            totalconflictdata
                          }}</span></v-flex
                        >
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap pa-0 justify-center>
                    <v-flex xs10 pa-0 v-if="flag">
                      <highcharts
                        :options="highchartsOptions"
                        :constructor-type="'chart'"
                      ></highcharts>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex> -->
              <v-flex xs12 sm12 md12 lg12 pl-lg-2 pt-sm-md-2>
                <v-card elevation="1" height="100%">
                  <v-layout wrap justify-start>
                    <v-flex xs12 lg6>
                      <v-layout wrap justify-start pa-5>
                        <v-flex xs12>
                          <v-layout wrap justify-start>
                            <v-flex xs12
                              ><span class="reportone"
                                >Animal Conflict Reports</span
                              ></v-flex
                            >
                            <v-flex xs12 pt-2
                              ><span class="reporttwo"
                                >Detailed Breakdown of Incidents Across
                              </span></v-flex
                            >
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pt-4>
                          <v-layout wrap justify-start>
                            <v-flex xs12 md4 sm4 lg4>
                              <v-card
                                elevation="0"
                                style="background-color: #f7f7f7"
                              >
                                <v-layout wrap justify-start pa-5>
                                  <v-flex xs12>
                                    <span class="reportfive"
                                      >Total conflicts Reported</span
                                    >
                                  </v-flex>
                                  <v-flex xs12>
                                    <v-layout wrap justify-start>
                                      <v-flex
                                        xs12
                                        sm6
                                        md6
                                        lg6
                                        class="text-left"
                                        align-self-start
                                      >
                                        <v-img
                                          :src="
                                            require('@/assets/images/mapicon.png')
                                          "
                                          contain
                                          height="45px"
                                        ></v-img
                                      ></v-flex>
                                      <v-flex
                                        xs12
                                        sm6
                                        md6
                                        lg6
                                        class="text-right"
                                        align-self-center
                                        ><span class="reportsix">{{
                                          totalReports
                                        }}</span></v-flex
                                      >
                                    </v-layout>
                                  </v-flex>
                                </v-layout>
                              </v-card>
                            </v-flex>
                            <v-flex xs12 md4 sm4 lg4 pl-2>
                              <v-layout wrap justify-start pb-1>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Idukki')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#786D17"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Idukki</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[5]
                                          "
                                          >{{
                                            districtWiseCounts[5].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Malappuram')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#B83E89"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Malappuram</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[10]
                                          "
                                          >{{
                                            districtWiseCounts[10].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 md4 sm4 lg4 pl-2>
                              <v-layout wrap justify-start pb-1>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Kollam')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#9B6AD0"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Kollam</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[0]
                                          "
                                          >{{
                                            districtWiseCounts[0].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Ernakulam')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#22D6C4"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Ernakulam</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[6]
                                          "
                                          >{{
                                            districtWiseCounts[6].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pt-1>
                          <v-layout wrap justify-start>
                            <v-flex xs12 md4 sm4 lg4>
                              <v-layout wrap justify-start pb-1>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Kozhikode')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#FB38BB"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Kozhikode</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[9]
                                          "
                                          >{{
                                            districtWiseCounts[9].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Alappuzha')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#6BDA7C"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Alappuzha</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[3]
                                          "
                                          >{{
                                            districtWiseCounts[3].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 md4 sm4 lg4 pl-2>
                              <v-layout wrap justify-start pb-1>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Kottayam')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#F05429"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Kottayam</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[4]
                                          "
                                          >{{
                                            districtWiseCounts[4].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Thrissur')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#EDED59"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Thrissur</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[12]
                                          "
                                          >{{
                                            districtWiseCounts[12].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 md4 sm4 lg4 pl-2>
                              <v-layout wrap justify-start pb-1>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Kannur')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#CD186B"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Kannur</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[7]
                                          "
                                          >{{
                                            districtWiseCounts[7].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Trivandrum')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#7D0460"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Trivandrum</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[1]
                                          "
                                          >{{
                                            districtWiseCounts[1].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pt-1>
                          <v-layout wrap justify-start>
                            <v-flex xs12 md4 sm4 lg4>
                              <v-layout wrap justify-start pb-1>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="
                                      redirectToDistrict('Pathanamthitta')
                                    "
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#76CC1C"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Pathanamthitta</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[2]
                                          "
                                          >{{
                                            districtWiseCounts[2].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 md4 sm4 lg4 pl-2>
                              <v-layout wrap justify-start>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Palakkad')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#8ABC30"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Palakkad</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[11]
                                          "
                                          >{{
                                            districtWiseCounts[11].count
                                          }}</span
                                        >
                                        <span v-else> 0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs12 md4 sm4 lg4 pl-2>
                              <v-layout wrap justify-start pb-1>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Wayanad')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#563CF0"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Wayanad</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[13]
                                          "
                                          >{{
                                            districtWiseCounts[13].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pb-3>
                          <v-layout wrap justify-start>
                            <v-flex xs12 md4 sm4 lg4>
                              <v-layout wrap justify-start>
                                <v-flex xs12>
                                  <v-card
                                    elevation="0"
                                    style="background-color: #f7f7f7"
                                    @click="redirectToDistrict('Kasaragod')"
                                  >
                                    <v-layout wrap justify-start pa-5>
                                      <v-flex xs1>
                                        <v-avatar
                                          color="#EE7D47"
                                          size="10"
                                        ></v-avatar>
                                      </v-flex>
                                      <v-flex xs9
                                        ><span class="districtname"
                                          >&nbsp;Kasaragod</span
                                        ></v-flex
                                      >
                                      <v-flex xs2
                                        ><span
                                          class="districtvalue"
                                          v-if="
                                            districtWiseCounts &&
                                            districtWiseCounts[8]
                                          "
                                          >{{
                                            districtWiseCounts[8].count
                                          }}</span
                                        >
                                        <span v-else>0</span></v-flex
                                      >
                                    </v-layout>
                                  </v-card>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 lg6>
                      <v-layout wrap justify-center pt-lg-10>
                        <v-flex xs12>
                          <v-layout wrap justify-center>
                            <v-flex xs10 v-if="flag">
                              <highcharts
                                :options="highchartsOptions"
                                :constructor-type="'chart'"
                              ></highcharts>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout wrap justify-center pa-5>
          <v-flex xs12>
            <v-layout wrap justify-start>
              <v-flex xs12 sm12 md12 lg6>
                <v-card elevation="1" height="100%">
                  <v-layout wrap justify-start pa-5>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12
                          ><span class="reportone"
                            >Year-wise Animal Conflict Reports</span
                          ></v-flex
                        >
                        <v-flex xs12 pt-3
                          ><span class="reporttwo"
                            >Annual breakdown of reported animal conflicts,
                            categorized by year
                          </span></v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <v-layout wrap justify-start>
                        <v-flex
                          xs12
                          lg4
                          v-for="(item, index) in yearData"
                          :key="index"
                          pa-1
                        >
                          <v-card
                            style="background-color: #f6f6f6"
                            elevation="0"
                          >
                            <v-layout wrap justify-center pa-5>
                              <v-flex
                                xs12
                                class="text-center yearhead"
                                align-self-center
                                style="color: #6594f8"
                                pa-2
                              >
                                <v-icon color="#6594F8"> mdi-calendar</v-icon
                                ><span>{{ item.year }}</span>
                              </v-flex>
                              <v-flex xs12><v-divider></v-divider></v-flex>
                              <v-flex xs12 pa-2 class="text-center yearsub">{{
                                item.totalReports
                              }}</v-flex>
                            </v-layout>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md12 lg6 pl-lg-2 pt-md-sm-2>
                <v-card elevation="1" height="100%">
                  <v-layout wrap justify-start pa-5>
                    <v-flex xs12>
                      <v-layout wrap justify-start>
                        <v-flex xs12 lg9>
                          <v-layout wrap justify-start>
                            <v-flex>
                              <span class="reportone"
                                >Animal Conflicts by Threat Level</span
                              >
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 lg3>
                          <v-select
                            outlined
                            dense
                            :hide-details="true"
                            :items="yearitems"
                            v-model="lineyeardata"
                          ></v-select>
                        </v-flex>
                        <v-flex xs12
                          ><span class="reporttwo"
                            >shows a visual breakdown of animal conflicts by
                            threat level.
                          </span></v-flex
                        >
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pt-2>
                      <v-layout wrap justify-start>
                        <v-flex xs12 v-if="flag1">
                          <highcharts
                            :options="linehighchartsOptions"
                            ref="linehighchartsRef"
                          ></highcharts>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      flag: false,
      flag1: false,
      dateitems: [
        "Today",
        "Yesterday",
        "Last 7 days",
        "Last 30 days",
        "Last 60 days",
        "Last 90 days",
      ],
      options: {
        chart: {
          type: "donut",
        },
        labels: [], // For the animal names
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      series: [],
      datefilter: "Last 30 days",
      cardData: [
        {
          image: require("@/assets/images/humandeath.png"),
          title: "Human Deaths",
          value: "",
          vert: require("@/assets/images/vert1.png"),
        },
        {
          image: require("@/assets/images/animaldeath.png"),
          title: "Animal Deaths",
          value: "",
          vert: require("@/assets/images/vert2.png"),
        },
        {
          image: require("@/assets/images/humaninjury.png"),
          title: "Human Injury ",
          value: "",
          vert: require("@/assets/images/vert3.png"),
        },
        {
          image: require("@/assets/images/animalcapptured.png"),
          title: "Animals Captured",
          value: "",
          vert: require("@/assets/images/vert4.png"),
        },
        {
          image: require("@/assets/images/animalreleased.png"),
          title: "Animals Released",
          value: "",
          vert: require("@/assets/images/vert5.png"),
        },
        {
          image: require("@/assets/images/animalinjured.png"),
          title: "Animals Injured",
          value: "",
          vert: require("@/assets/images/vert6.png"),
        },
        {
          image: require("@/assets/images/elephantreported.png"),
          title: "Elephant Reported",
          value: "",
          vert: require("@/assets/images/vert7.png"),
        },
        {
          image: require("@/assets/images/tigerreported.png"),
          title: "Tiger Reported",
          value: "",
          vert: require("@/assets/images/vert8.png"),
        },
      ],
      casecardData: [
        {
          title: "Total Cases",
          value: "34",
        },
        {
          title: "Pending Cases",
          value: "23",
        },
        {
          title: "Resolved Cases",
          value: "45",
        },
        {
          title: "Released Cases",
          value: "26",
        },
      ],
      yearData: [],
      linechartseries: [
        {
          name: "Low threat",
          data: [50, 40, 60, 70, 80],
        },
        {
          name: "Moderate threat",
          data: [30.2, 30, 10, 50, 60],
        },
        {
          name: "High threat",
          data: [20, 25, 45, 67, 30],
        },
      ],
      linechartoptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#07E098", "#F6D635", "#F33232"],
      },
      yearitems: ["2024", "2025", "2026"],
      lineyeardata: new Date().getFullYear().toString(),
      totalAnimalDeaths: "",
      totalReleased: "",
      totalCaptured: "",
      totalHumanDeaths: "",
      totalHumanInjuries: "",
      totalconflictdata: 0,
      districtWiseCounts: [],
      totalReports: "",
      highchartsOptions: {
        chart: {
          type: "pie",
          height: 400,
        },
        exporting: {
          enabled: false, // Disable the export menu
        },
        credits: {
          enabled: false, // Disable the Highcharts watermark
        },
        title: {
          text: null,
          enabled: true,
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.y}</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            borderRadius: 10,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return (
                  "<b>" +
                  this.point.name.charAt(0).toUpperCase() +
                  this.point.name.slice(1).toLowerCase() +
                  "</b>: " +
                  this.point.y // Display count instead of percentage
                );
              },
            },
          },
        },
        series: [
          {
            name: "Reports",
            colorByPoint: true,
            innerSize: "0%",
            data: [], // Data will be dynamically populated
          },
        ],
      },
      seriesData: [],
      // linehighchartsOptions: {
      //   chart: {
      //     type: "area",
      //     height: 350,
      //   },
      //   exporting: {
      //     enabled: false, // Disable the export menu
      //   },
      //   credits: {
      //     enabled: false, // Disable the Highcharts watermark
      //   },
      //   title: {
      //     text: null, // To disable the title text
      //   },
      //   xAxis: {
      //     categories: [], // Will be populated with months from backend
      //   },
      //   yAxis: {
      //     title: {
      //       text: "Total Reports",
      //     },
      //   },
      //   series: [
      //     { name: "High threat", data: [] },
      //     { name: "Medium threat", data: [] },
      //     { name: "Low threat", data: [] },
      //   ],
      //   colors: ["#F33232", "#F6D635", "#07E098"], // Update colors to match previous chart
      //   plotOptions: {
      //     line: {
      //       dataLabels: {
      //         enabled: true,
      //       },
      //       enableMouseTracking: true,
      //     },
      //   },
      // },
      linehighchartsOptions: {
        chart: {
          type: "area",
          height: 450,
          backgroundColor: "#FFFFFF", // Subtle background color
          style: {
            fontFamily: "Arial, sans-serif", // Set a modern font
          },
        },
        exporting: {
          enabled: false, // Disable the export menu
        },
        credits: {
          enabled: false, // Disable the Highcharts watermark
        },
        title: {
          text: "Threat Level Analysis Over Time", // Add a meaningful title
          align: "center",
          style: {
            fontSize: "16px",
            fontWeight: "bold",
          },
        },
        xAxis: {
          categories: [], // Will be populated with months from the backend
          title: {
            text: "Months",
            style: {
              fontWeight: "bold",
              fontSize: "14px",
            },
          },
          lineColor: "#333",
          tickColor: "#333",
        },
        yAxis: {
          title: {
            text: "Total Reports",
            style: {
              fontWeight: "bold",
              fontSize: "14px",
            },
          },
          gridLineColor: "#e6e6e6", // Light grid lines
        },
        series: [
          {
            name: "High threat",
            data: [],
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [
                [0, "rgba(243, 50, 50, 0.8)"],
                [1, "rgba(243, 50, 50, 0.1)"],
              ],
            },
            lineWidth: 2,
            marker: {
              radius: 4,
              symbol: "circle",
              fillColor: "#F33232",
            },
          },
          {
            name: "Medium threat",
            data: [],
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [
                [0, "rgba(246, 214, 53, 0.8)"],
                [1, "rgba(246, 214, 53, 0.1)"],
              ],
            },
            lineWidth: 2,
            marker: {
              radius: 4,
              symbol: "square",
              fillColor: "#F6D635",
            },
          },
          {
            name: "Low threat",
            data: [],
            fillColor: {
              linearGradient: [0, 0, 0, 300],
              stops: [
                [0, "rgba(7, 224, 152, 0.8)"],
                [1, "rgba(7, 224, 152, 0.1)"],
              ],
            },
            lineWidth: 2,
            marker: {
              radius: 4,
              symbol: "diamond",
              fillColor: "#07E098",
            },
          },
        ],
        colors: ["#F33232", "#F6D635", "#07E098"], // Ensure consistent colors
        tooltip: {
          shared: true, // Show combined tooltips
          borderColor: "#333",
          backgroundColor: "#fff",
          borderRadius: 10,
          style: {
            fontSize: "12px",
          },
        },
        plotOptions: {
          area: {
            stacking: "normal", // Add stacking for better visualization
            marker: {
              enabled: true,
              symbol: "circle",
              radius: 3,
              states: {
                hover: {
                  enabled: true,
                  radius: 6,
                },
              },
            },
          },
        },
      },
    };
  },
  beforeMount() {
    this.getData();
    this.animalcountData();
    this.threatlevelData();
    this.districtCount();
    this.yearDatafull();
  },
  mounted() {
    // Optional: If the year list needs to be extended dynamically
    const currentYear = new Date().getFullYear();
    if (!this.yearitems.includes(currentYear.toString())) {
      this.yearitems.push(currentYear.toString());
      this.yearitems.sort(); // Ensure the list is sorted
    }
  },
  computed: {
    appUser() {
      return this.$store.state.mainRole;
    },
    currentDate() {
      // Format today's date as needed
      return new Date().toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
  },
  watch: {
    lineyeardata() {
      this.threatlevelData();
    },
    datefilter() {
      this.getData();
      this.animalcountData();
      this.threatlevelData();
      this.districtCount();
      this.yearDatafull();
    },
  },
  methods: {
    redirectToDistrict(districtName) {
      if (this.appUser === "official") {
        this.$router.push({
          path: "/officers/district-details",
          query: { name: districtName },
        });
      } else {
        this.$router.push({
          path: "/admin/district-details",
          query: { name: districtName },
        });
      }
    },
    redirectToPage(title) {
      if (this.appUser === "official") {
        this.$router.push({
          path: "/officers/card-details",
          query: { name: title },
        });
      } else {
        this.$router.push({
          path: "/admin/card-details",
          query: { name: title },
        });
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/dashboard/count",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          filter: this.datefilter,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.cardData[0].value = response.data.data.totalHumanDeaths;
            this.cardData[1].value = response.data.data.totalAnimalDeaths;
            this.cardData[2].value = response.data.data.totalHumanInjuries;
            this.cardData[3].value = response.data.data.totalCaptured;
            this.cardData[4].value = response.data.data.totalReleased;
            this.cardData[5].value = response.data.data.totalAnimalInjuries;
            this.cardData[6].value = response.data.data.totalElephantReports;
            this.cardData[7].value = response.data.data.totalTigerReports;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    districtCount() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hwc/reportcounts/districtwise",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          filter: this.datefilter,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.districtWiseCounts = response.data.data.districtWiseCounts;
            this.totalReports = response.data.data.totalReports;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    yearDatafull() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hwc/reportcounts/yearwise",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          filter: this.datefilter,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.yearData = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    animalcountData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/animalsummary/reports",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          filter: this.datefilter,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            const animalReportSummary = response.data.data.animalReportSummary;
            this.series = animalReportSummary.map((item) => item.totalReports);
            this.options.labels = animalReportSummary.map((item) => item.name);
            this.totalconflictdata = response.data.data.totalReports;
            const seriesData = animalReportSummary.map((item) => ({
              name: item.name,
              y: item.totalReports,
            }));
            this.highchartsOptions.series[0].data = seriesData;
            this.flag = true;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    threatlevelData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/threatlevel/reports",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          year: this.lineyeardata,
          filter: this.datefilter,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            const threatData = response.data.data;
            const months = threatData.map((item) => item.month);
            const highThreat = threatData.map((item) => {
              const level = item.threatLevels.find(
                (level) => level.threatlevel === "High"
              );
              return level ? level.totalReports : 0;
            });
            const mediumThreat = threatData.map((item) => {
              const level = item.threatLevels.find(
                (level) => level.threatlevel === "Medium"
              );
              return level ? level.totalReports : 0;
            });
            const lowThreat = threatData.map((item) => {
              const level = item.threatLevels.find(
                (level) => level.threatlevel === "Low"
              );
              return level ? level.totalReports : 0;
            });
            this.linechartseries = [
              { name: "High threat", data: highThreat },
              { name: "Moderate threat", data: mediumThreat },
              { name: "Low threat", data: lowThreat },
            ];
            this.linechartoptions = {
              ...this.linechartoptions,
              xaxis: {
                categories: months, // Months as x-axis labels
              },
            };
            this.linehighchartsOptions.xAxis.categories = months;
            this.linehighchartsOptions.series[0].data = highThreat;
            this.linehighchartsOptions.series[1].data = mediumThreat;
            this.linehighchartsOptions.series[2].data = lowThreat;
            this.flag1 = true;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  
  <style>
.heading {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.dropdown {
  font-family: poppinsregular;
  font-weight: 500;
  font-size: 15px;
  line-height: 22.5px;
}
.custom-select-background .v-input__control {
  background-color: rgba(209, 224, 252, 0.7);
}
.cardheader {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* opacity: 50%; */
  color: #797979;
}
.cardvalue {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}
.reportone {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.reporttwo {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 1);
  /* opacity: 50%; */
  color: #797979;
}
.reportthree {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.reportfour {
  font-family: poppinsregular;
  font-weight: 700;
  font-size: 35px;
  line-height: 52.5px;
}
.reportfive {
  font-family: poppinsregular;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #494949;
}
.reportsix {
  font-family: poppinsregular;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.districtname {
  font-family: poppinsregular;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  color: #797979;
}
.districtvalue {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
}
.yearhead {
  font-family: poppinsregular;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
.yearsub {
  font-family: poppinsregular;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
}
.baccolor {
  /* background-color:#1D976C; */
   background-image: linear-gradient(to right, #43e97b 0%, #38f9d7 100%);
}
</style>
  