<template>
    <div>
      <v-layout wrap justify-end>
        <v-flex xs12>
          <v-layout wrap justify-center>
            <v-flex xs12 text-center align-self-center>
              <v-btn block plain :ripple="false" @click="redirectToNotifications">
                  <v-icon size="30" color="#000">mdi-bell</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  export default {
    computed: {
      appNotification() {
        return this.$store.state.notification || 0;
      },
      appUser() {
      return this.$store.state.userRole;
    },
    },
    methods: {
      redirectToNotifications() {
        if(this.appUser=== 'admin'){
        this.$router.push("/Admin/notifications");
        }
        else{
            this.$router.push("/Officers/notifications");
        }
      },
    },
  };
  </script>
  