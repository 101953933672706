<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12 lg8 md8 sm12>
            <span class="heading">Filter Data</span>
          </v-flex>
          <v-flex xs12 lg4 md4 sm12 class="text-end">
            <span class="subheading"
              >Total Records Found: {{ totalcount }}</span
            >
          </v-flex>
          <v-flex xs12>
            <span
              style="color: red; font-family: poppinsregular; font-size: 13px"
              >Please select the main criterias for search.
            </span>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm11 md11 lg7>
                <v-layout wrap pl-1>
                  <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="alldata"
                      label="All"
                      @change="checkUpdate()"
                      class="small-checkbox no-space"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="timeperiod"
                      label="Time Period"
                      :disabled="alldata"
                      class="small-checkbox no-space"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="yearwise"
                      label="Yearwise"
                      :disabled="alldata"
                      class="small-checkbox no-space"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="divisioncheck"
                      label="Office"
                      :disabled="alldata"
                      class="small-checkbox no-space"
                    ></v-checkbox>
                  </v-flex>
                  <!-- <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="rangecheck"
                      label="Range"
                      :disabled="alldata"
                      class="small-checkbox no-space"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="rrtcheck"
                      label="RRT"
                      :disabled="alldata"
                      class="small-checkbox no-space"
                    ></v-checkbox>
                  </v-flex> -->
                  <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="casestatuscheck"
                      label="Status"
                      :disabled="alldata"
                      class="small-checkbox no-space"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="animalcheck"
                      label="Animal"
                      :disabled="alldata"
                      class="small-checkbox no-space"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="scenariocheck"
                      label="Scenario"
                      :disabled="alldata"
                      class="small-checkbox no-space"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex class="text-left subheading">
                    <v-checkbox
                      v-model="threatlevelcheck"
                      label="Threat Level"
                      :disabled="alldata"
                      class="small-checkbox no-space"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex wrap justify-start lg5 pt-2>
                <v-layout wrap>
                  <v-flex>
                    <v-btn
                      color="#fdac53"
                      class="downlink"
                      target="_blank"
                      @click="getExcel()"
                    >
                      <span
                        style="
                          font-size: 12px;
                          font-family: poppinssemibold;
                          color: black;
                        "
                      >
                        Excel<v-icon color="black"
                          >mdi-microsoft-excel</v-icon
                        ></span
                      >
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-md-2
                    pl-lg-2
                    pl-sm-2
                    v-if="timeperiod"
                  >
                    <span class="subheading">From Date</span>
                    <!-- <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Date"
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="itemform"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        no-title
                        scrollable
                        :max="blockdate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu> -->
                    <v-menu
                      v-model="menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Date"
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="itemform"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        @input="menu = false"
                        :max="blockdate"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-md-2
                    pl-lg-2
                    pl-sm-2
                    v-if="timeperiod"
                  >
                    <span class="subheading">To Date</span>
                    <!-- <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      :return-value.sync="todate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="todate"
                          label="Date"
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="itemform"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        no-title
                        scrollable
                        :max="blockdate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu1 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu1.save(todate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu> -->
                    <v-menu
                      v-model="menu1"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="todate"
                          label="Date"
                          outlined
                          :disabled="!date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="itemform"
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        @input="menu1 = false"
                        :min="date"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-md-2
                    pl-lg-2
                    pl-sm-2
                    v-if="divisioncheck"
                  >
                    <span class="subheading">Division</span>
                    <v-autocomplete
                      outlined
                      label="Division Name"
                      :items="divisionList"
                      v-model="divisionname"
                      item-text="divisionName"
                      item-value="_id"
                      class="itemform"
                      dense
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-md-2
                    pl-lg-2
                    pl-sm-2
                    v-if="divisioncheck"
                  >
                    <span class="subheading">Range</span>
                    <v-autocomplete
                      outlined
                      label="Range Name"
                      :items="rangeList"
                      v-model="rangename"
                      item-text="rangeName"
                      item-value="_id"
                      class="itemform"
                      dense
                      :disabled="divisionname ? false : true"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-md-2
                    pl-lg-2
                    pl-sm-2
                    v-if="divisioncheck"
                  >
                    <span class="subheading">RRT</span>
                    <v-autocomplete
                      outlined
                      label="RRT Name"
                      :items="rrtList"
                      v-model="rrtname"
                      item-text="name"
                      item-value="_id"
                      class="itemform"
                      :disabled="rangename ? false : true"
                      dense
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-md-2
                    pl-lg-2
                    pl-sm-2
                    v-if="casestatuscheck"
                  >
                    <span class="subheading">Status</span>
                    <v-autocomplete
                      outlined
                      label="Case Status"
                      :items="caseStatuslist"
                      v-model="casestatus"
                      class="itemform"
                      dense
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-md-2
                    pl-lg-2
                    pl-sm-2
                    v-if="animalcheck"
                  >
                    <span class="subheading">Animal</span>
                    <v-select
                      :items="animalTypes"
                      outlined
                      item-text="name"
                      item-value="_id"
                      label="Animal Type"
                      v-model="animaltype"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-md-2
                    pl-lg-2
                    pl-sm-2
                    v-if="scenariocheck"
                  >
                    <span class="subheading">Scenario</span>
                    <v-select
                      :items="scenariodata"
                      outlined
                      item-text="name"
                      item-value="_id"
                      label="Scenario Type"
                      v-model="scenarioname"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-md-2
                    pl-lg-2
                    pl-sm-2
                    v-if="threatlevelcheck"
                  >
                    <span class="subheading">Threat Level</span>
                    <v-select
                      :items="threatLevels"
                      label="Threat Level"
                      outlined
                      v-model="threatlevel"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    sm3
                    md3
                    lg3
                    pl-md-2
                    pl-lg-2
                    pl-sm-2
                    v-if="yearwise"
                  >
                    <span class="subheading">Yearwise</span>
                    <v-select
                      :items="yearwisedata"
                      label="Yearwise"
                      outlined
                      v-model="yearwiseselect"
                      class="itemform"
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex
            xs12
            v-if="
              timeperiod ||
              casestatuscheck ||
              divisioncheck ||
              rangecheck ||
              rrtcheck ||
              animalcheck ||
              scenariocheck ||
              threatlevelcheck ||
              yearwise
            "
          >
            <v-layout wrap justify-start>
              <v-flex
                xs12
                sm3
                md3
                lg3
                pl-md-2
                pl-lg-2
                pl-sm-2
                align-self-center
              >
                <v-btn
                  block
                  depressed
                  color="primary"
                  class="itemform"
                  @click="getData()"
                >
                  <span>Apply Filters</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 v-if="items && items.length > 0">
            <v-layout wrap justify-start pt-2>
              <v-flex xs12>
                <v-card>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center tableheading">
                            Reported Date
                          </th>
                          <th class="text-center tableheading">Animal</th>
                          <th class="text-center tableheading">Scenario</th>
                          <th class="text-center tableheading">Location</th>
                          <th class="text-center tableheading">Threat Level</th>
                          <th class="text-center tableheading">Division</th>
                          <th class="text-center tableheading">Range</th>
                          <th class="text-center tableheading">Status</th>
                          <th class="text-center tableheading">Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in items" :key="index">
                          <td class="tablesubheading text-center">
                            {{ formatDate(item.reportDate) }}
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.animalId && item.animalId.name">{{
                              item.animalId.name
                            }}</span>
                            <span v-else>Unknown</span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span
                              v-if="item.scenarioId && item.scenarioId.name"
                              >{{ item.scenarioId.name }}</span
                            >
                            <span v-else>Unknown</span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.locationname">{{
                              item.locationname
                            }}</span>
                            <span v-else>Unknown</span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.threatlevel">{{
                              item.threatlevel
                            }}</span>
                            <span v-else>Unknown</span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.divisions.length > 0">
                              <span
                                v-for="(item1, i) in item.divisions"
                                :key="i"
                              >
                                <span v-if="item1.divisionName"
                                  >{{ item1.divisionName }}
                                  <span v-if="i < item.divisions.length - 1"
                                    >,
                                  </span></span
                                >
                              </span>
                            </span>
                            <span v-else> - </span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.ranges.length > 0">
                              <span v-for="(item2, i) in item.ranges" :key="i">
                                <span v-if="item2.rangeName"
                                  >{{ item2.rangeName }}
                                  <span v-if="i < item.ranges.length - 1"
                                    >,
                                  </span></span
                                >
                              </span>
                            </span>
                            <span v-else> Unknown </span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="item.status">{{ item.status }}</span>
                            <span v-else>Unknown</span>
                          </td>
                          <td class="tablesubheading text-center">
                            <span v-if="casestatus !== 'Resolved'">
                              <v-btn
                                color="success"
                                depressed
                                small
                                @click="openviewdetails(item)"
                                ><span>View Details</span></v-btn
                              >
                            </span>
                            <span v-if="casestatus === 'Resolved'">
                              <v-btn
                                color="success"
                                depressed
                                small
                                @click="openresolvedviewdetails(item)"
                                ><span>View Details</span></v-btn
                              >
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-4 v-if="items && items.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              circle
              color="#FF6907"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else pl-lg-3>
        <v-layout wrap justify-center pl-5>
          <v-flex xs12 class="text-center">
            <span class="heading">No Data Found!Apply Filters.</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Delete Rescuer</v-card-title>
        <v-card-text>
          Are you sure you want to delete this rescuer?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="deleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteUser()"
            class="itemValue"
          >
            <span style="color: #fff">Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
          <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      count: 20,
      keyword: "",
      currentPage: 1,
      Pagelength: 0,
      userlist: [],
      districts: [
        "Palakkad",
        "Alappuzha",
        "Malappuram",
        "Kannur",
        "Wayanad",
        "Kozhikode",
        "Thrissur",
        "Thiruvananthapuram",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Kollam",
        "Pathanamthitta",
        "Kasaragod",
      ],
      deleteDialog: false,
      items: [],
      divisionname: "",
      divisionList: [],
      rangeList: [],
      rangename: "",
      rrtname: "",
      rrtList: [],
      animalTypes: [],
      animaltype: "",
      scenariodata: [],
      scenarioname: "",
      casestatus: "Active",
      caseStatuslist: ["Active", "Assigned", "Resolved", "Released"],
      menu: false,
      menu1: false,
      todate: null,
      date: null,
      blockdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      threatLevels: ["High", "Medium", "Low"],
      threatlevel: "",

      alldata: true,
      timeperiod: false,
      casestatuscheck: false,
      divisioncheck: false,
      rangecheck: false,
      rrtcheck: false,
      yearwise: false,
      animalcheck: false,
      scenariocheck: false,
      threatlevelcheck: false,
      totalcount: 0,
      yearwisedata: ["2024", "2025"],
      yearwiseselect: "",
    };
  },
  beforeMount() {
    this.getData();
    this.getDivisionData();
    // this.getRangeData();
    // this.getRRTData();
    this.getanimalData();
    this.getscenarioData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    divisionname() {
      this.getRangeData();
    },
    rangename() {
      this.getRRTData();
    },
    casestatus() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
    },
    date() {
      this.todate == null;
    },
  },
  computed: {
    appUser() {
      return this.$store.state.userRole;
    },
  },
  methods: {
    openviewdetails(item) {
      this.$router.push({
        path: "/Admin/cases/caseDetails/?id=",
        query: {
          id: item._id,
        },
      });
    },
    checkUpdate() {
      if (this.alldata == true) {
        this.timeperiod = false;
        this.casestatuscheck = false;
        this.divisioncheck = false;
        this.rangecheck = false;
        this.rrtcheck = false;
        this.animalcheck = false;
        this.scenariocheck = false;
        this.threatlevelcheck = false;
        this.date = null;
        this.todate = null;
        this.divisionname = null;
        this.rangename = null;
        (this.rrtname = null), (this.casestatus = "Active");
        this.animaltype = null;
        this.scenarioname = null;
        this.threatlevel = null;
        this.yearwiseselect = null;
        this.yearwise = false;
        this.getData();
      }
    },
    openresolvedviewdetails(item) {
      this.$router.push({
        path: "/Admin/cases/caseDetails",
        query: {
          id: item.reportId,
          status: "Resolved", // Add the status parameter here
        },
      });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hwc/report/alllist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          division: this.divisionname,
          range: this.rangename,
          rrt: this.rrtname,
          animalId: this.animaltype,
          scenarioId: this.scenarioname,
          status: this.casestatus,
          fromDate: this.date,
          toDate: this.todate,
          page: this.currentPage,
          limit: this.count,
          threatlevel: this.threatlevel,
          year: this.yearwiseselect,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.items = response.data.data;
              this.totalcount = response.data.totalLength;
              // var temp = [];
              // for (var i = 0; i < this.items.length; i++) {
              //   if (this.items[i].reportId != null)
              //     var it = this.items[i].reportId;
              //   it._id = this.items[i]._id;
              //   it.reportedAt = this.items[i].reportedAt;
              //   if (this.items[i].reportId != null) {
              //     if (this.items[i].reportId.snake)
              //       it.snake = this.items[i].reportId.snake.name;
              //   }
              //   temp.push(it);
              // }
              // this.items = temp;
              this.Pagelength = Math.ceil(
                response.data.totalLength / this.count
              );
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    getDivisionData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/platform/division/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.divisionList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRangeData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/platform/range/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          division: this.divisionname,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rangeList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRRTData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/rrt/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          division: this.divisionname,
          range: this.rangename,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rrtList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getanimalData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/animal/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.animalTypes = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/report/generation/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          division: this.divisionname,
          range: this.rangename,
          rrt: this.rrtname,
          animalId: this.animaltype,
          scenarioId: this.scenarioname,
          status: this.casestatus,
          fromDate: this.date,
          toDate: this.todate,
          // page: this.currentPage,
          // limit: this.count,
          threatlevel: this.threatlevel,
          year: this.yearwiseselect,
        },
      })
        .then((response) => {
          this.appLoading = false;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Report.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getscenarioData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/scenario/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.scenariodata = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(date) {
      if (!date) return "-";
      const [year, month, day] = date.slice(0, 10).split("-");
      return `${day}-${month}-${year}`;
    },
  },
};
</script>
          <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
.tableheading {
  font-family: poppinsregular;
  font-size: 15px !important;
  color: black !important;
  text-transform: uppercase !important;
}
.tablesubheading {
  font-family: poppinsregular;
  font-size: 13px !important;
}
</style>