<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-start pa-5>
            <v-flex xs12>
              <span class="heading">Training List</span>
            </v-flex>
            <v-flex xs12>
              <v-layout wrap justify-start pt-2>
                <!-- <v-flex xs12 sm6 md6 lg6 class="subheading">
                  <span>Rows per page</span>
                  <v-select
                    v-model="count"
                    color="#FF6907"
                    outlined
                    dense
                    hide-details
                    :items="[20, 30, 50]"
                  ></v-select>
                </v-flex> -->
                <v-flex xs12 sm3 md3 lg3>
                <span class="subheading">Filter</span>
                <v-text-field
                  outlined
                  hide-details
                  dense
                  placeholder="Type To Search"
                  v-model="keyword"
                >
                </v-text-field>
              </v-flex>
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2>
                  <span class="subheading">District</span>
                <v-select
                  outlined
                  hide-details
                  placeholder="Select"
                  dense
                  :items="districts"
                  v-model="district"
                >
                </v-select>
                </v-flex>
                <v-flex xs12 sm3 md3 lg3 pl-md-2 pl-lg-2 pl-sm-2 align-self-center pt-5>
                <v-btn
                  color="#fdac53"
                  class="downlink"
                  target="_blank"
                  @click="getExcel()"
                >
                  <span
                    style="
                      font-size: 12px;
                      font-family: poppinssemibold;
                      color: black;
                    "
                  >
                    Excel<v-icon color="black"
                      >mdi-microsoft-excel</v-icon
                    ></span
                  >
                </v-btn>
              </v-flex>
              </v-layout>
              <v-layout wrap justify-start pt-2 v-if="items && items.length > 0">
                <v-flex xs12>
                  <v-card>
                    <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center tableheading">Name</th>
                          <th class="text-center tableheading">Phone</th>
                          <th class="text-center tableheading">Email</th>
                          <th class="text-center tableheading">District</th>
                          <th class="text-center tableheading">Action</th>
                          <!-- <th class="text-center tableheading">Delete</th> -->
                        </tr>
                      </thead>
                      <tbody class="tablesubheading">
                        <tr v-for="(item, index) in items" :key="index" class="text-center">
                          <td><span v-if="item.user && item.user.name">{{item.user.name}}</span>
                            <span v-else>-</span></td>
                          <td><span v-if="item.user && item.user.phone">{{item.user.phone}}</span>
                            <span v-else>-</span></td>
                          <td><span v-if="item.user && item.user.email">{{ item.user.email }}</span>
                          <span v-else>-</span></td>
                          <td><span v-if="item && item.district">{{ item.district }}</span>
                            <span v-else>-</span></td>
                          <td>
                            <!-- <v-btn
                              color="success"
                              depressed
                              small
                              @click="openviewdetails(item)"
                              ><span>View Details</span></v-btn
                            > -->
                            <span><v-icon small @click="openviewdetails(item)" color="blue">mdi-eye</v-icon></span>
                          <span><v-icon small class="ml-5" @click="opendeleteDialog(item.user)" color="red">mdi-delete</v-icon></span>
                          </td>
                          <!-- <td>
                            <v-btn
                              color="error"
                              depressed
                              small
                             @click="opendeleteDialog(item)"
                              ><span>Delete</span></v-btn
                            >
                          </td> -->
                          <!-- <td>
                            <v-btn
                              color="error"
                              depressed
                              small
                              @click="openrejectDialog(item)"
                              ><span>Reject</span></v-btn
                            >
                          </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 pb-4 v-if="items && items.length > 0">
          <v-layout wrap justify-center>
            <v-flex xs10>
              <v-pagination
                v-model="currentPage"
                :length="Pagelength"
                :total-visible="7"
                circle
                color="#FF6907"
                small
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 v-else>
          <v-layout wrap justify-center pl-5>
            <v-flex xs12 class="text-center">
              <span class="heading"
                >No Data Found!</span
              >
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-dialog v-model="deleteDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Confirmation</v-card-title>
          <v-card-text
            >Are you sure you want to delete?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="deleteDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="deleteoperation()"
              class="itemValue"
            >
              <span style="color: #fff">Submit</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rejectDialog" max-width="500">
        <v-card>
          <v-card-title class="heading">Confirmation</v-card-title>
          <v-card-text
            >Are you sure you want to reject?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="rejectDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="rejectoperation()"
              class="itemValue"
            >
              <span style="color: #fff">Submit</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
          <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        count: 20,
        keyword: "",
        currentPage: 1,
        Pagelength: 0,
        userlist: [],
        items: [],
        status:"",
        deleteDialog: false,
        deleteid:"",
        rejectDialog:false,
        rejectid:"",
        districts: [
          "All",
        "Palakkad",
        "Alappuzha",
        "Malappuram",
        "Kannur",
        "Wayanadu",
        "Kozhikode",
        "Thrissur",
        "Thiruvananthapuram",
        "Kottayam",
        "Idukki",
        "Ernakulam",
        "Kollam",
        "Pathanamthitta",
        "Kasaragod",
      ],
      district:"",
      };
    },
    beforeMount() {
      this.getData();
    },
    watch: {
      currentPage() {
        this.getData();
      },
      count() {
        this.getData();
      },
      keyword() {
        this.getData();
      },
      district(){
        this.getData();
        this.currentPage = 1;
      }
    },
    methods: {
      openviewdetails(item) {
        this.$router.push({
          path: "/traininglistview",
          query: {
            id: item._id,
          },
        });
      },
      
      opendeleteDialog(item) {
        this.deleteid = item._id;
        this.deleteDialog = true;
      },
      openrejectDialog(item) {
        this.rejectid = item._id;
        this.rejectDialog = true;
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/rescuer/traininglist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params:{
            page:this.currentPage,
            count:this.count,
            keyword:this.keyword,
            district:this.district
          }
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.items = response.data.data;
                this.Pagelength = response.data.pages;
                // this.msg = response.data.msg;
                // this.showSnackBar = true;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      deleteoperation() {
        this.appLoading = true;
        axios({
          method: "DELETE",
          url: "/user/delete",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.deleteid,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = "Deleted Successfully.";
                this.showSnackBar = true;
                this.appLoading = false;
                this.deleteDialog=false;
                this.getData();
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.deleteDialog=false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      rejectoperation() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/operation/removedeleterequest",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.rejectid,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = "Rejected Successfully.";
                this.showSnackBar = true;
                this.appLoading = false;
                this.rejectDialog=false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.rejectDialog=false;

            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/traininglist/download/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          keyword: this.keyword,
          district:this.district,
        },
      })
        .then((response) => {
          this.appLoading = false;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "TrainingList.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    },
  };
  </script>
          <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  .tableheading {
  font-family: poppinsregular;
  font-size: 15px !important;
  color: black !important;
  text-transform: uppercase !important;
}
.tablesubheading {
  font-family: poppinsregular;
  font-size: 10px;
}
  </style>