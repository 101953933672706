<template>
  <div class="app-wrapper">
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <div class="map-container">
      <!-- Control button -->
      <div id="control" v-cloak>
        <v-btn icon @click="toggleControlPanel" class="control-btn">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>

      <!-- Control panel -->
      <div id="overlay" :class="{ in: showControlPanel }">
        <v-card elevation="0">
          <v-card-title class="itemSubHeading">Map Controls</v-card-title>
          <v-card-text>
            <v-layout wrap justify-center class="smallLightHeading">
              <v-flex xs12 pa-0>
                <v-layout wrap justify-start>
                  <v-flex xs12 lg6>
                    <v-checkbox
                      v-model="clusterMarkers"
                      label="Cluster Locations"
                      @change="updateMap"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12 lg6>
                    <v-checkbox
                      v-if="!clusterMarkers"
                      v-model="showLegend"
                      label="Add Legend"
                      @change="updateMap"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <span class="itemSubHeading">Marker colour based on</span>
              </v-flex>
              <v-flex xs12 class="smallLightHeading">
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <v-radio-group
                      v-model="markerColorBy"
                      @change="updateMarkerColors"
                    >
                      <v-radio label="Animal Name" value="common"></v-radio>
                      <v-radio label="Threat Level" value="schedule"></v-radio>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <span class="itemSubHeading">Add Layers to Map</span>
              </v-flex>
              <v-flex xs12 class="smallLightHeading" pa-0 pt-0>
                <v-layout wrap justify-start>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="showCirclesLayer"
                      dense
                      hide-details="false"
                      label="Circles"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="showDivisionsLayer"
                      dense
                      hide-details="false"
                      label="Divisions"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="showRangesLayer"
                      dense
                      hide-details="false"
                      label="Ranges"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="showPASLayer"
                      dense
                      hide-details="false"
                      label="Protected Areas"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex xs12>
                    <v-checkbox
                      v-model="showForestLayer"
                      dense
                      hide-details="false"
                      label="Forests"
                      class="small-checkboxmap"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-4>
              <v-flex xs12>
                <span class="itemSubHeading">Data Filter Section</span>
              </v-flex>
              <v-flex xs12 pt-3 class="smallLightHeading">
                <v-layout wrap justify-center>
                  <v-flex xs12>
                    <span>Animal Name</span>
                    <v-select
                      v-model="selectedCommonName"
                      dense
                      outlined
                      hide-details="auto"
                      :items="commonNames"
                      clearable
                      placeholder="Please Choose"
                      item-text="name"
                      item-value="_id"
                      @change="updateFilters"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>Threat Level</span>
                    <v-select
                      dense
                      outlined
                      hide-details="auto"
                      v-model="selectedSchedule"
                      clearable
                      :items="schedules"
                      placeholder="Please Choose"
                      @change="updateFilters"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>Situation</span>
                    <v-select
                      dense
                      outlined
                      hide-details="auto"
                      clearable
                      v-model="selectedReason"
                      :items="reasons"
                      placeholder="Please Choose"
                      @change="updateFilters"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>Case Status</span>
                    <v-select
                      dense
                      outlined
                      hide-details="auto"
                      v-model="casestatus"
                      :items="casestatusList"
                      clearable
                      placeholder="Please Choose"
                      @change="updateFilters"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>Circle</span>
                    <v-autocomplete
                      clearable
                      outlined
                      hide-details
                      :items="circleList"
                      placeholder="Please Choose"
                      v-model="circlename"
                      item-text="circleName"
                      item-value="_id"
                      dense
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>Division</span>
                    <v-autocomplete
                      clearable
                      outlined
                      dense
                      placeholder="Please Choose"
                      hide-details
                      v-model="divisionname"
                      :items="divisionlist"
                      item-text="divisionName"
                      item-value="_id"
                      :disabled="circlename ? false : true"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>Range</span>
                    <v-autocomplete
                      outlined
                      dense
                      clearable
                      placeholder="Please Choose"
                      hide-details
                      v-model="rangename"
                      item-text="rangeName"
                      item-value="_id"
                      :items="rangelist"
                      :disabled="divisionname ? false : true"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>RRT</span>
                    <v-autocomplete
                      outlined
                      dense
                      clearable
                      placeholder="Please Choose"
                      hide-details
                      v-model="rrtname"
                      item-text="name"
                      item-value="_id"
                      :items="rrtlist"
                      :disabled="rangename ? false : true"
                    >
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>From Date</span>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          append-icon="mdi-calendar"
                          readonly
                          placeholder="Please Choose"
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          dense
                          hide-details="auto"
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 pt-1>
                    <span>To Date</span>
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :return-value.sync="todate"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="todate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          clearable
                          v-on="on"
                          placeholder="Please Choose"
                          dense
                          hide-details="auto"
                          outlined
                          :disabled="!date"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate"
                        no-title
                        scrollable
                        :min="date"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu2 = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu2.save(todate)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </div>

      <!-- Map component -->
      <l-map
        ref="map"
        class="full-map"
        :center="center"
        :zoom="zoom"
        :scroll-wheel-zoom="false"
        :zoomControl="false"
        :attributionControl="false"
        @ready="onMapReady"
      >
        <l-tile-layer :url="url"></l-tile-layer>
        <!-- State Boundary Layer -->
        <l-geo-json
          v-if="showStateLayer"
          :geojson="keralaGeoJSON"
          :options="geoJSONStyle"
        ></l-geo-json>
        <!-- Districts Boundary Layer -->
        <l-geo-json
          v-if="showCirclesLayer"
          :geojson="keralaCirclesGeoJSON"
          :options="circleGeoJSONStyle"
        ></l-geo-json>
        <l-geo-json
          v-if="showDivisionsLayer"
          :geojson="keralaDivisionsGeoJSON"
          :options="divisionGeoJSONStyle"
        ></l-geo-json>
        <l-geo-json
          v-if="showRangesLayer"
          :geojson="keralaRangesGeoJSON"
          :options="rangeGeoJSONStyle"
        ></l-geo-json>
        <l-geo-json
          v-if="showPASLayer"
          :geojson="keralaPASGeoJSON"
          :options="PASGeoJSONStyle"
        ></l-geo-json>
        <l-geo-json
          v-if="showForestLayer"
          :geojson="keralaFORESTGeoJSON"
          :options="FORESTGeoJSONStyle"
        ></l-geo-json>
        <!-- Markers -->
        <l-marker
          v-for="(marker, index) in filteredMarkers"
          :key="index"
          :lat-lng="marker.latlng"
          :icon="getMarkerIcon(marker)"
        >
          <l-popup :content="marker.popupContent"></l-popup>
        </l-marker>
      </l-map>

      <!-- Legend -->
      <div v-if="showLegend && !clusterMarkers" class="legend">
        <h3>Legend</h3>
        <div v-for="(color, label) in legendItems" :key="label">
          <span :style="{ backgroundColor: color }" class="legend-color"></span>
          {{ label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LGeoJson } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet.markercluster";
import axios from "axios";
export default {
  name: "MapComponent",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LGeoJson,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 7,
      center: [10.8505, 76.2711],
      markers: [],
      showControlPanel: false,
      clusterMarkers: false,
      clusterGroup: null,
      casestatusList: ["Pending", "Assigned", "Resolved", "Released"],
      casestatus: "",
      showLegend: false,
      markerColorBy: "common",
      showStateLayer: false,
      showCirclesLayer: false,
      showDivisionsLayer: false,
      showRangesLayer: false,
      showPASLayer: false,
      showForestLayer: false,
      selectedCommonName: null,
      selectedSchedule: null,
      keralaDivisionsGeoJSON: {},
      selectedReason: null,
      commonNames: [],
      schedules: ["High", "Medium", "Low"],
      reasons: ["Direct Sighting", "Indirect Sign"],
      keralaGeoJSON: {},
      keralaCirclesGeoJSON: {},
      keralaRangesGeoJSON: {},
      keralaPASGeoJSON: {},
      keralaForestGeoJSON: {},
      geoJSONStyle: {
        style: {
          weight: 2,
          color: "#000",
          opacity: 1,
          fillOpacity: 0,
          dashArray: "5, 10",
        },
      },
      circleGeoJSONStyle: {
        style: {
          weight: 2,
          color: "#000",
          opacity: 1,
          fillOpacity: 0,
          dashArray: "0, 0",
        },
      },
      divisionGeoJSONStyle: {
        style: {
          weight: 2,
          color: "#00008B",
          opacity: 1,
          fillOpacity: 0,
          dashArray: "0, 0",
        },
      },
      rangeGeoJSONStyle: {
        style: {
          weight: 2,
          color: "#FF0000",
          opacity: 1,
          fillOpacity: 0,
          dashArray: "0, 0",
        },
      },
      PASGeoJSONStyle: {
        style: {
          weight: 2,
          color: "#800080",
          opacity: 1,
          fillOpacity: 0,
          dashArray: "0, 0",
        },
      },
      FORESTGeoJSONStyle: {
        style: {
          weight: 2,
          color: "#008000",
          opacity: 1,
          fillOpacity: 0,
          dashArray: "0, 0",
        },
      },
      date: null,
      menu: false,
      menu2: false,
      todate: null,
      fullanimaldata: [],
      circleList: [],
      circlename: "",
      divisionname: "",
      divisionlist: [],
      rangename: "",
      rangelist: [],
      rrtname: "",
      rrtlist: [],
    };
  },
  computed: {
    filteredMarkers() {
      return this.markers.filter((marker) => {
        if (
          this.selectedCommonName &&
          marker.commonName._id !== this.selectedCommonName
        )
          return false;
        if (this.selectedSchedule && marker.schedule !== this.selectedSchedule)
          return false;
        if (this.selectedReason && marker.reason !== this.selectedReason)
          return false;
        if (this.casestatus && marker.status !== this.casestatus) return false;
        if (this.circlename && marker.circle !== this.circlename) return false;
        if (this.divisionname && marker.division !== this.divisionname)
          return false;
        if (this.rangename && marker.range !== this.rangename) return false;
        if (this.rrtname && marker.rrt !== this.rrtname) return false;
        if (this.date && new Date(marker.reportDate) < new Date(this.date))
          return false;
        if (this.todate && new Date(marker.reportDate) > new Date(this.todate))
          return false;
        return true;
      });
    },
    legendItems() {
      const items = {};
      if (this.markerColorBy === "common") {
        this.commonNames.forEach((item, index) => {
          items[item.name] = this.getColor(index);
        });
      } else if (this.markerColorBy === "schedule") {
        this.schedules.forEach((schedule, index) => {
          items[schedule] = this.getColor(index);
        });
      } else if (this.markerColorBy === "reason") {
        this.reasons.forEach((reason, index) => {
          items[reason] = this.getColor(index);
        });
      }
      return items;
    },
  },
  beforeMount() {
    this.getanimalData();
    this.getfullanimalData();
    this.getcircleData();
    this.getdivisionData();
    this.getrangeData();
    this.getpasData();
    this.getforestData();
    this.getStationList();
    this.getCircleList();
    this.getDivisionList();
    this.getRangeList();
    this.getRRTList();
    // this.getstateData();
  },
  mounted() {
    // Delete the default icon
    delete L.Icon.Default.prototype._getIconUrl;

    // Set a blank icon as the default
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
    this.addPopupStyles();
    document.body.addEventListener("click", (event) => {
      if (event.target.classList.contains("view-details-link")) {
        const itemId = event.target.getAttribute("data-id"); // Get the item ID
        const itemStatus = event.target.getAttribute("data-status"); // Get the status
        this.navigateToDetailsPage(itemId, itemStatus); // Call navigation method
      }
    });
    this.loadInitialMarkers();
  },
  watch: {
    date() {
      this.getfullanimalData();
    },
    todate() {
      this.getfullanimalData();
    },
    selectedCommonName() {
      this.loadInitialMarkers();
    },
    selectedSchedule() {
      this.loadInitialMarkers();
    },
    selectedReason() {
      this.loadInitialMarkers();
    },
    casestatus() {
      this.loadInitialMarkers();
    },
    circlename() {
      this.divisionname = "";
      this.rangename = "";
      this.rrtname = "";
      this.getfullanimalData();
      this.getDivisionList();
      this.loadInitialMarkers();
    },
    divisionname() {
      this.rangename = "";
      this.rrtname = "";
      this.getfullanimalData();
      this.getRangeList();
      this.loadInitialMarkers();
    },
    rangename() {
      this.rrtname = "";
      this.getfullanimalData();
      this.getRRTList();
      this.loadInitialMarkers();
    },
    rrtname() {
      this.getfullanimalData();
      this.loadInitialMarkers();
    },
  },
  methods: {
    toggleControlPanel() {
      this.showControlPanel = !this.showControlPanel;
    },
    getStationList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/platform/station/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCircleList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/platform/circle/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.circleList = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getDivisionList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/platform/division/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          circleId: this.circlename,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.divisionlist = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRangeList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/platform/range/getlistdropdown",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          division: this.divisionname,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rangelist = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getRRTList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/rrt/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          division: this.divisionname,
          range: this.rangename,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.rrtlist = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    updateMap() {
      this.loadInitialMarkers();
    },
    getanimalData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/animal/list",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.commonNames = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getcircleData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/shapes/circles/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.keralaCirclesGeoJSON = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getdivisionData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/shapes/divisions/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.keralaDivisionsGeoJSON = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getrangeData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/shapes/ranges/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.keralaRangesGeoJSON = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getpasData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/shapes/pas/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.keralaPASGeoJSON = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getforestData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/shapes/forest/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.keralaFORESTGeoJSON = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getstateData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/alldata/formap/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.keralaGeoJSON = response.data.data;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getfullanimalData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hwc/conflictreports/inmap",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          fromDate: this.date,
          toDate: this.todate,
          circle: this.circlename,
          division: this.divisionname,
          range: this.rangename,
          rrt: this.rrtname,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.fullanimaldata = response.data.data;
              this.loadInitialMarkers();
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    updateFilters() {
      this.loadInitialMarkers();
    },
    navigateToDetailsPage(itemId, itemStatus) {
      let query = { id: itemId }; // Default query parameter

      // Add status to query parameters based on conditions
      if (itemStatus === "Resolved") {
        query.status = "Resolved";
      } else if (itemStatus === "Released") {
        query.status = "Released";
      }

      // Navigate to the details page
      this.$router.push({
        path: "/Admin/cases/caseDetails/",
        query: query,
      });
    },
    updateMarkerColors() {
      this.loadInitialMarkers();
    },
    updateLayers() {
      if (this.$refs.map) {
        const map = this.$refs.map.mapObject;

        // Existing state layer logic
        if (this.showStateLayer) {
          map.fitBounds(L.geoJSON(this.keralaGeoJSON).getBounds(), {
            padding: [50, 50],
          });
        }

        // Add logic for circles (districts) layer
        if (this.showCirclesLayer) {
          map.fitBounds(L.geoJSON(this.keralaCirclesGeoJSON).getBounds(), {
            padding: [50, 50],
          });
        }

        if (this.showDivisionsLayer) {
          map.fitBounds(L.geoJSON(this.keralaDivisionsGeoJSON).getBounds(), {
            padding: [50, 50],
          });
        }

        if (this.showRangesLayer) {
          map.fitBounds(L.geoJSON(this.keralaRangesGeoJSON).getBounds(), {
            padding: [50, 50],
          });
        }

        if (this.showPASLayer) {
          map.fitBounds(L.geoJSON(this.keralaPASGeoJSON).getBounds(), {
            padding: [50, 50],
          });
        }

        if (this.showForestLayer) {
          map.fitBounds(L.geoJSON(this.keralaFORESTGeoJSON).getBounds(), {
            padding: [50, 50],
          });
        }

        console.log("Layers updated");
      }
    },
    onMapReady() {
      const map = this.$refs.map.mapObject;
      map.zoomControl.remove();
      this.loadInitialMarkers();
    },
    loadInitialMarkers() {
      // Clear existing markers and clusters
      if (this.clusterGroup) {
        this.clusterGroup.clearLayers();
        this.$refs.map.mapObject.removeLayer(this.clusterGroup);
      }
      this.$refs.map.mapObject.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          this.$refs.map.mapObject.removeLayer(layer);
        }
      });

      // Ensure fullanimaldata is loaded and valid
      if (!this.fullanimaldata || this.fullanimaldata.length === 0) {
        console.error("No data available to display markers.");
        return;
      }

      // Filter out invalid locations and map to markers
      this.markers = this.fullanimaldata
        .filter(
          (item) =>
            item.location &&
            Array.isArray(item.location) &&
            item.location.length >= 2
        )
        .map((item) => ({
          latlng: [item.location[1], item.location[0]],
          popupContent: this.createPopupContent(item),
          commonName: {
            _id: item.animalId ? item.animalId._id : "Unknown ID",
            name: item.animalId ? item.animalId.name : "Unknown Animal",
          },
          schedule: item.threatlevel,
          reason: item.scenarioId ? item.scenarioId.name : "Unknown",
          status: item.status,
          circle: item.circle,
          division: item.division,
          range: item.range,
          rrt: item.rrt,
          reportDate: item.reportDate,
        }));

      // Log markers for debugging
      // console.log("Markers:", this.markers);

      if (this.clusterMarkers) {
        // Create a new cluster group
        this.clusterGroup = L.markerClusterGroup({
          iconCreateFunction: (cluster) => {
            const childCount = cluster.getChildCount();
            let color;
            if (childCount < 10) {
              color = "#FF6384";
            } else if (childCount < 50) {
              color = "#36A2EB";
            } else if (childCount < 100) {
              color = "#FFCE56";
            } else {
              color = "#4BC0C0";
            }
            return L.divIcon({
              className: "custom-cluster-icon",
              html: `<div style="background-color: ${color}; color: white; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;">${childCount}</div>`,
              iconSize: [30, 30],
            });
          },
          spiderfyOnMaxZoom: false, // Disable spiderfy on max zoom
          showCoverageOnHover: false, // Disable coverage on hover
          zoomToBoundsOnClick: true, // Zoom to bounds on cluster click
        });

        // Add filtered markers to the cluster group
        this.filteredMarkers.forEach((marker) => {
          if (marker.latlng && marker.latlng.length >= 2) {
            const icon = this.getMarkerIcon(marker); // Use custom-colored marker icon
            const popup = L.popup().setContent(marker.popupContent);
            const mapMarker = L.marker(marker.latlng, { icon });
            mapMarker.bindPopup(popup);
            this.clusterGroup.addLayer(mapMarker);
          }
        });

        // Add the cluster group to the map
        this.$refs.map.mapObject.addLayer(this.clusterGroup);
      } else {
        // If clustering is disabled, add filtered markers directly to the map
        this.filteredMarkers.forEach((marker) => {
          if (marker.latlng && marker.latlng.length >= 2) {
            const icon = this.getMarkerIcon(marker); // Use custom-colored marker icon
            const popup = L.popup().setContent(marker.popupContent);
            const mapMarker = L.marker(marker.latlng, { icon });
            mapMarker.bindPopup(popup);
            this.$refs.map.mapObject.addLayer(mapMarker);
          }
        });
      }
    },
    createPopupContent(item) {
      const animalName = item.animalId ? item.animalId.name : "Unknown Animal";
      const scenarioName = item.scenarioId
        ? item.scenarioId.name
        : "Unknown Scenario";
      const threatLevel = item.threatlevel || "Not Specified";
      const status = item.status || "No Status";
      const formattedDate = item.reportDate
        ? new Date(item.reportDate).toLocaleDateString("en-GB")
        : "No Date";

      let coordinates = "Coordinates Not Available";
      if (item.location && item.location.length >= 2) {
        coordinates = `${item.location[1].toFixed(
          4
        )}, ${item.location[0].toFixed(4)}`;
      }

      return `
    <div class="marker-popup">
      <h3 class="popup-title">${animalName}</h3>
      <div class="popup-details">
        <p><strong>Scenario:</strong> ${scenarioName}</p>
        <p><strong>Threat Level:</strong> ${threatLevel}</p>
        <p><strong>Status:</strong> ${status}</p>
        <p><strong>Reported Date:</strong> ${formattedDate}</p>
        <p><strong>Coordinates:</strong> ${coordinates}</p>
        <p 
          class="view-details-link" 
          data-id="${item._id}" 
          data-status="${status}"
        >
          View Details
        </p>
      </div>
    </div>
  `;
    },

    addPopupStyles() {
      const style = document.createElement("style");
      style.textContent = `
        .marker-popup {
          font-family: Arial, sans-serif;
          max-width: 250px;
        }
        .popup-title {
          color: #333;
          border-bottom: 1px solid #eee;
          padding-bottom: 5px;
          margin-bottom: 10px;
        }
        .popup-details p {
          margin: 5px 0;
          font-size: 0.9em;
        }
        .popup-details strong {
          color: #666;
          margin-right: 5px;
        }
      `;
      document.head.appendChild(style);
    },
    getMarkerIcon(marker) {
      const iconColor = this.getMarkerColor(marker);
      const size = 13; // Set the desired size here (e.g., 8 pixels)

      return L.divIcon({
        className: "custom-marker-icon",
        html: `<div style="
        background-color: ${iconColor}; 
        width: ${size}px; 
        height: ${size}px; 
        border-radius: 50%; 
        border: 1px solid white; 
        box-shadow: 0 0 3px rgba(0,0,0,0.3);
      "></div>`,
        iconSize: [size, size], // Set the icon size to match the div size
        iconAnchor: [size / 2, size / 2], // Center the anchor point
      });
    },
    getMarkerColor(marker) {
      if (this.markerColorBy === "common") {
        const index = this.commonNames.findIndex(
          (item) => item._id === marker.commonName._id
        );
        return this.getColor(index);
      } else if (this.markerColorBy === "schedule") {
        // Map threat levels to indices
        const threatLevels = ["High", "Medium", "Low"];
        const index = threatLevels.indexOf(marker.schedule);
        return this.getColor(index);
      } else if (this.markerColorBy === "reason") {
        const index = this.reasons.indexOf(marker.reason);
        return this.getColor(index);
      }
      return "#000000";
    },
    // getColor(index) {
    //   const colors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"];
    //   return colors[index % colors.length];
    // },
    getColor(index) {
      const colors = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
        "#4B0082",
        "#FFD700",
        "#ADFF2F",
        "#FF69B4",
      ];
      return colors[index % colors.length];
    },
    // Alternatively, you can generate random colors
    generateRandomColor() {
      const randomColor =
        "#" + Math.floor(Math.random() * 16777215).toString(16);
      return randomColor;
    },
  },
};
</script>

    <style>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Prevents scrolling */
}

.app-wrapper {
  width: 99vw; /* Use viewport width */
  height: 100vh; /* Use viewport height */
  position: relative;
  margin: 0; /* Remove any margin */
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%; /* Ensure full width */
  height: 100%; /* Ensure full height */
  overflow: hidden; /* Prevent overflow */
}

.custom-marker-icon {
  background: none !important; /* Remove any background */
  border: none !important; /* Remove any border */
  box-shadow: none !important; /* Remove any shadow */
  width: 20px; /* Set width */
  height: 20px; /* Set height */
  border-radius: 50%; /* Ensure it is circular */
}

.leaflet-marker-icon {
  background: none !important;
  border: none !important;
}

.full-map {
  width: 100%;
  height: 100%;
}

#overlay {
  position: absolute;
  z-index: 1000;
  width: 300px;
  background: rgba(255, 255, 255, 0.9);
  padding: 10px;
  left: -310px; /* Keep this for control panel toggle */
  top: 60px;
  border-radius: 10px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
  transition: left 0 0.3s ease-in-out;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}

#overlay.in {
  left: 10px;
}

.control-btn {
  position: absolute;
  z-index: 1001;
  top: 10px;
  left: 10px;
}

.legend {
  position: absolute;
  z-index: 1000;
  right: 10px;
  bottom: 20px;
  max-height: 300px;
  width: fit-content;
  min-width: 150px;
  background: rgba(255, 255, 255, 0.8);
  overflow-y: auto;
  padding: 10px;
  border-radius: 5px;
}

.legend-color {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 5px;
}

.view-details-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.view-details-link:hover {
  color: #0056b3;
}

.custom-cluster-icon {
  background: none !important; /* Remove any default background */
  border: none !important; /* Remove any default border */
  box-shadow: none !important; /* Remove any default shadow */
}
</style>